<template>
  <main class="main h-100 w-100">
    <slot />
  </main>
</template>

<script setup lang="ts">
const { $api } = useNuxtApp();
const router = useRouter();
const route = useRoute();

const fetchAuth = async () => {
  try {
    const result = await $api('/v1/auth/auth', {
      method: 'GET',
    });

    if (result) {
      await router.push('/main/dashboard');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

onMounted(async () => {
  await fetchAuth();
});
</script>
<style scoped lang="scss">
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
</style>
